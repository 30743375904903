const CURRENCIES = {
  // fiat
  USD: 'USD',
  JPY: 'JPY',
  // crypto currencies
  USDT: 'USDT',
  ETH: 'ETH',
  WETH: 'WETH',
  MATIC: 'MATIC',
  WMATIC: 'WMATIC',
  POINT: 'POINT',
};

export const getTypeCurrency = (t, value) => {
  switch (value) {
    case 'MCC_POINT':
      return t('MCC_POINT');
    case 'FREE_POINT':
      return t('FREE_POINT');
    default:
      return value
  }
};


export const WRAPPED_CURRENCIES = [CURRENCIES.WETH, CURRENCIES.WMATIC];

export default CURRENCIES;
