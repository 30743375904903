import React from 'react';

import './resources/styles';
import Store from './store';
import Theme from './theme';
import Init from './app/init';
import Routes from './app/routes';
import Confirmable from './components/confirmable';
import './translations/i18n';

import './App.less';
import Providers from './Providers';
import MaskLoading from './components/mask-loading';

const App = () => (
  <Store>
    <Theme>
      <Providers>
        <Init>
          <Routes />
        </Init>
        <MaskLoading ref={ref => MaskLoading.setInstance(ref)} />
        <Confirmable ref={ref => Confirmable.setInstance(ref)} />
      </Providers>
    </Theme>
  </Store>
);

export default App;
