import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, Modal } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import styled from 'styled-components';
import { MobXProviderContext } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Storage from '../utils/storage';
import { Images } from '../theme';
import Button from './button';
import MaskLoading from './mask-loading';
import Confirmable from './confirmable';
import Toast from './toast';

const ModalVerifyEmailStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    background: #1b1e24;
  }

  .ant-modal-body {
    color: #fff;
    text-align: center;

    .ant-form {
      color: #fff;
    }

    .error {
      color: red;
      font-size: 13px;
      font-weight: 500;
    }

    .title {
      font-size: 20px;
      padding-bottom: 20px;
      font-weight: 900;
      text-transform: uppercase;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 500;
      text-transform: none;
      text-align: start;
      margin-bottom: 15px;
    }

    input {
      border-radius: 8px;
      background: #1b1e24;
      height: 60px;
      color: #fff;

      &::placeholder {
        color: #fff;
      }
    }

    .action-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 30px;

      .btn-vr {
        background: #045afc;
      }

      .generate {
        color: #2b0c01;

        &.disabled {
          color: #fff;
        }
      }
    }

    @media (max-width: 575px) {
      padding: 16px;
      padding-bottom: 24px;

      .action-box {
        .btn-vr {
          height: 42px !important;
        }
      }

      .sub-title {
        font-size: 13px;
      }
    }
  }
`;

const ModalVerifyEmailSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  .title {
    font-size: 32px;
    font-weight: 400;
  }

  .content {
    font-size: 18px;
    font-weight: 400;
  }
`;

export const ModalContext = React.createContext();

export const ModalVerifyEmailProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState();

  return (
    <ModalContext.Provider
      value={useMemo(
        () => ({
          hide: () => setIsOpen(false),
          open: () => setIsOpen(true),
        }),
        [],
      )}
    >
      {isOpen ? <ModalInvalidEmail setIsOpen={setIsOpen} /> : null}
      {children}
    </ModalContext.Provider>
  );
};

const schema = Yup.object().shape({
  email: Yup.string().required('This field is required').email('Email invalid'),
});

export const ModalInvalidEmail = ({ setIsOpen }) => {
  const { t } = useTranslation('auth');
  const { auth } = useContext(MobXProviderContext);
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    getValues,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {
    trigger();
    if (isValid) {
      requestVerifyEmail(data);
    }
  };

  const requestVerifyEmail = async value => {
    const IS_CHECK = Storage.get("IS_CHECK");
    const ACCESS_TOKEN = Storage.get("ACCESS_TOKEN");
    MaskLoading.open({});
    try {
      const { data, success } = await auth.verifyEmail({value, headers: ACCESS_TOKEN ? ACCESS_TOKEN : IS_CHECK});
      if (success && data.success) {
        setIsOpen(false);

        Confirmable.open({
          content: (
            <ModalVerifyEmailSuccess>
              <div className='icon'><img src={Images.ICON_EMAIL} alt='icon-mail' /></div>
              <div className='title'>{t('login.messages.title_verify_email')}</div>
              <div className='content'>{t('login.messages.message_verify_email_success', { email: value.email })}</div>
            </ModalVerifyEmailSuccess>
          ),
          hideCancelButton: true,
          hideOkButton: true,
          width: 650,
          maskClosable: false
        });
      }
      MaskLoading.close();
    } catch (error) {
      if (error?.error) {
        Toast.error(error?.error);
      }
      MaskLoading.close();
      setIsOpen(false);
    }
  };

  return (
    <>
      <ModalVerifyEmailStyled
        open={true}
        width={450}
        footer={null}
        destroyOnClose
        closable={false}
        maskClosable={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="title">{t('login.messages.complete_your_profile')}</div>
          <div>
            <label htmlFor="email">
              <div className="sub-title">
              {t('login.messages.message_verify_email')}
              </div>
            </label>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input id="email" name="email" placeholder={t('login.email')} onChange={onChange} value={value || ''}></Input>
              )}
            />
            {errors?.email && <p className="error">{errors.email.message}</p>}
          </div>
          <div className="action-box">
            <Button
              onClick={() => onSubmit(getValues())}
              className="btn-vr"
            >{t('login.submit_btn')}</Button>
          </div>
        </Form>
      </ModalVerifyEmailStyled>
    </>
  );
};
