import authStore from '../store/auth';
import Storage from '../utils/storage';
import Request from '../utils/request';
import { getBalanceNew } from '../utils/web3';

export const useAuth = () => {
  const onLoginAction = async ({ web3Provider, publicAddress, web3 }) => {
    try {
      const {
        success,
        data: { message },
      } = await authStore.getRandomMessage({
        publicAddress,
      });

      if (web3Provider && success && message) {
        const signature = await web3.eth.personal.sign(message, publicAddress, '');
        const loginResult = await authStore.login({ publicAddress, message, signature });

        if (loginResult.success) {
          const { data } = loginResult;
          Storage.set('ACCESS_TOKEN', data.accessToken);
          Storage.set('USER_ID', data.userId);
          Request.setAccessToken(data.accessToken);
          await authStore.setLoggedIn(true);
          // const { eth } = await getBalance(publicAddress);
          const { native: nativeBalance } = await getBalanceNew(publicAddress);
          await authStore.getInitialData({
            //   ethBalance: eth,
            nativeBalance,
            publicAddress,
            userId: data.userId,
          });
          await authStore.getInitialData();
          // history.push(location.pathname + location.search);
        }
      }
    } catch (error) {
      throw error;
    }
  };
  const logoutAction = () => {};

  return {
    onLoginAction,
    logoutAction,
  };
};
