import { Input } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  width: 100%;
  height: 40px;

  border: 1px solid #000000;
  box-shadow: 0px 1px 1px 0px #000000;
  border-radius: 6px;
`;

const InputType = ({ children, className, ...props }) => {
  return <StyledInput {...props} className={classnames(className)} />;
};
InputType.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default InputType;
