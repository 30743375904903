import { getPublicCompressed } from '@toruslabs/eccrypto';
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from '@web3auth/base';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Web3 from 'web3';
import RPC from './evm';

import { t } from 'i18next';
import Confirmable from '../../components/confirmable';
import Toast from '../../components/toast';
import { CHAIN_LIST } from '../../constants/chains';
import { getNetworkSelected } from '../../utils/auth';
import Misc from '../../utils/misc';
import Storage from '../../utils/storage';
import { getWalletProvider } from './walletProvider';

export const Web3AuthContext = createContext({
  web3Auth: null,
  provider: null,
  isLoading: false,
  user: null,
  expiredToken: null,
  refreshToken: null,
  login: async (adapter, provider, login_hint) => {},
  logout: async () => {},
  getUserInfo: async () => {},
  getAccounts: async () => {},
  getBalance: async () => {},
  getAppPubKey: async () => {},
  getPrivateKey: async () => {},
  signMessage: async () => {},
  ethereum: null,
  setExpiredToken: () => {},
  setRefreshToken: () => {},
  loggedInWeb3auth: false,
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}

const env = process.env.REACT_APP_ENV;
// const networkWeb3auth = process.env.REACT_APP_WEB3_AUTH_NETWORKS;
const network = getNetworkSelected(env, Storage.get('chain'));
const currentChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: '0x1', // Please use 0x1 for Mainnet
  rpcTarget: 'https://rpc.ankr.com/eth',
  displayName: 'Ethereum Mainnet',
  blockExplorerUrl: 'https://etherscan.io/',
  ticker: 'ETH',
  tickerName: 'Ethereum',
};

const clientId = process.env.REACT_APP_WEB3_AUTH_CLIENT_ID;

export const Web3AuthProvider = ({ children }) => {
  const [web3Auth, setWeb3Auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [expiredToken, setExpiredToken] = useState(Storage.get('EXPIRED_TOKEN') || null);
  const [refreshToken, setRefreshToken] = useState(Storage.get('REFRESH_TOKEN') || null);

  useEffect(() => {
    async function init() {
      try {
        if (localStorage.getItem('Web3Auth-cachedAdapter')) setIsLoading(true);

        const web3AuthInstance = new Web3AuthNoModal({
          chainConfig: currentChainConfig,
          enableLogging: true,
          clientId: clientId,
          // uiConfig: {
          //   appName: 'Bounty Hunters App',
          //   mode: 'light', // light, dark or auto
          //   logoLight: 'https://remoteclawstorage.blob.core.windows.net/dev-remote-claw-blob/1715049820432_logo.png',
          //   logoDark: 'https://remoteclawstorage.blob.core.windows.net/dev-remote-claw-blob/1715049820432_logo.png',
          //   defaultLanguage: 'en', // en, de, ja, ko, zh, es, fr, pt, nl, tr
          // },
        });

        const openloginAdapter = new OpenloginAdapter({
          privateKeyProvider: new EthereumPrivateKeyProvider({
            config: {
              chainConfig: currentChainConfig,
            },
          }),
          // adapterSettings: {
          //   clientId,
          //   uxMode: UX_MODE.REDIRECT,
          //   loginConfig: {
          //     google: {
          //       verifier: 'bth-dev-main-aggregate-verifier',
          //       verifierSubIdentifier: 'bth-dev-google-verifier',
          //       typeOfLogin: 'google',
          //       clientId: '449456393211-vmmik8heb0s9bkll8s5f1mcd7iha2m3h.apps.googleusercontent.com',
          //     },
          //     // facebook: {
          //     //   verifier: "bth-dev-main-aggregate-verifier",
          //     //   verifierSubIdentifier: "facebook-verifier",
          //     //   typeOfLogin: "facebook",
          //     //   clientId: "2412131582313780",
          //     // },
          //     auth0emailpasswordless: {
          //       verifier: 'bth-dev-main-aggregate-verifier',
          //       verifierSubIdentifier: 'bth-dev-passwordless-verifier',
          //       typeOfLogin: 'jwt',
          //       clientId: '5KSddOX1HUsmtcYIEFwh6Ss4XfeLxFsV',
          //     },
          //   },
          // },
          loginSettings: {
            mfaLevel: 'none',
          },
          adapterSettings: {
            clientId,
          },
        });

        web3AuthInstance.configureAdapter(openloginAdapter);

        await web3AuthInstance.init();

        setWeb3Auth(web3AuthInstance);
        if (web3AuthInstance.connected) {
          setLoggedIn(true);
        }
        setProvider(web3AuthInstance.provider);
        if (!web3AuthInstance?.provider) setIsLoading(false);
      } catch (error) {
        Toast.error(error?.message || 'Initial web3 auth fail.');
      } finally {
        setIsLoading(false);
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginGoogle = async () => {
    try {
      if (!web3Auth) {
        return;
      }
      const web3authProvider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: 'google',
      });
      setProvider(web3authProvider);

      return {
        publicAddress: await getAccounts(),
        userInfo: await getUserInfo(),
        appPubKey: await getAppPubKey(),
      };
    } catch (error) {
      if (error?.code === 5111) {
        await logout();
      }

      if (error.error === undefined && error.mesage === undefined) {
        if (Misc.isMobile && !Misc.checkNotMetamaskBrowser()) {
          Confirmable.open({
            content: (
              <>
                {t('validation_messages:SOMETHING_WENT_WRONG')}
                <br />
                {t('common:metamask_switch_network_to', { network: CHAIN_LIST[network?.chainId].displayName })}
              </>
            ),
            hideCancelButton: true,
          });
        }
      } else {
        await logout();
      }
      throw error;
    }
  };

  const loginAuth0EmailPasswordLess = async email => {
    try {
      if (!web3Auth) {
        return;
      }
      const web3authProvider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: 'email_passwordless',
        login_hint: email,
      });
      setProvider(web3authProvider);
      return {
        publicAddress: await getAccounts(),
        userInfo: await getUserInfo(),
        appPubKey: await getAppPubKey(),
      };
    } catch (error) {
      if (error?.code === 5111) {
        await logout();
      }

      if (error.error === undefined && error.mesage === undefined) {
        if (Misc.isMobile && !Misc.checkNotMetamaskBrowser()) {
          Confirmable.open({
            content: (
              <>
                {t('validation_messages:SOMETHING_WENT_WRONG')}
                <br />
                {t('common:metamask_switch_network_to', { network: CHAIN_LIST[network?.chainId].displayName })}
              </>
            ),
            hideCancelButton: true,
          });
        }
      } else {
        await logout();
      }
      throw error;
    }
  };

  const login = async (adapter, loginProvider, login_hint) => {
    try {
      if (!web3Auth) {
        return;
      }
      const localProvider = await web3Auth.connectTo(adapter, { loginProvider, login_hint });
      // await setWalletProvider(localProvider);

      const walletProvider = getWalletProvider(network?.chainId, localProvider);
      return {
        publicAddress: await walletProvider.getAccounts(),
        privateKey: adapter === WALLET_ADAPTERS.METAMASK ? undefined : await walletProvider.getPrivateKey(),
      };
    } catch (error) {
      if (error?.code === 5111) {
        await logout();
      }

      if (error.error === undefined && error.mesage === undefined) {
        if (Misc.isMobile && !Misc.checkNotMetamaskBrowser()) {
          Confirmable.open({
            content: (
              <>
                {t('validation_messages:SOMETHING_WENT_WRONG')}
                <br />
                {t('common:metamask_switch_network_to', { network: CHAIN_LIST[network?.chainId].displayName })}
              </>
            ),
            hideCancelButton: true,
          });
        }
      } else {
        await logout();
      }
      throw error;
    }
  };

  const logout = async () => {
    if (!web3Auth) {
      return;
    }
    try {
      if (web3Auth.connected) {
        await web3Auth.logout();
        setLoggedIn(false);
      }
    } catch (e) {}

    setProvider(null);
  };

  const getUserInfo = async () => {
    try {
      if (!web3Auth) {
        return;
      }

      const user = await web3Auth?.getUserInfo();

      return user;
    } catch (error) {
      return error?.message;
    }
  };

  const getAccounts = useCallback(async () => {
    if (!provider) {
      return null;
    }
    const rpc = new RPC(provider);
    const userAccount = await rpc.getAccounts();

    return userAccount;
  }, [provider]);

  const getChainId = async () => {
    if (!provider) {
      return null;
    }
    const chainId = await provider.getCurrentChainId();

    return chainId;
  };

  const getBalance = async () => {
    if (!provider) {
      return;
    }
    const balance = await provider.getBalance();

    return balance;
  };

  const getAppPubKey = async () => {
    try {
      const app_scoped_privkey = await provider?.request({
        method: 'eth_private_key', // use "private_key" for other non-evm chains
      });

      const app_pub_key = getPublicCompressed(Buffer.from(app_scoped_privkey.padStart(64, '0'), 'hex')).toString('hex');

      return app_pub_key;
    } catch (error) {
      return error;
    }
  };

  const getPrivateKey = useCallback(async () => {
    if (!provider) {
      return null;
    }

    try {
      const privateKeyWeb3Auth = await provider?.getPrivateKey();

      return privateKeyWeb3Auth;
    } catch (error) {
      return null;
    }
  }, [provider]);

  const signMessage = async message => {
    try {
      const web3 = new Web3(provider);

      // Get user's Ethereum public address
      // const fromAddress = (await web3.eth.getAccounts())[0];
      const fromAddress = (await provider.getAccounts())[0];

      // const sign = await provider.signMessage();

      // Sign the message
      const signedMessage = await web3.eth.personal.sign(
        message,
        fromAddress,
        '', // configure your own password here.
      );

      return signedMessage;
    } catch (error) {
      return error;
    }
  };

  const contextProvider = {
    web3Auth,
    provider,
    isLoading,
    expiredToken,
    refreshToken,
    setIsLoading,
    login,
    logout,
    getUserInfo,
    getChainId,
    getAccounts,
    getBalance,
    getAppPubKey,
    getPrivateKey,
    signMessage,
    setExpiredToken,
    setRefreshToken,
    loginGoogle,
    loginAuth0EmailPasswordLess,
    loggedInWeb3auth: loggedIn,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
