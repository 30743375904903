import PropTypes from 'prop-types';
import React, { Component, Suspense, lazy } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Loading from '../components/loading';
import Page from '../components/page';
import Request from '../utils/request';
import ScrollToTop from '../utils/scrollToTop';
import Storage from '../utils/storage';
import { ROUTE_OPTION } from './constants';
import Header from './header';

const Home = lazy(() => import('../pages/home'));
const Products = lazy(() => import('../pages/products'));
const ProductDetails = lazy(() => import('../pages/product-details'));
const UserProfile = lazy(() => import('../pages/user-profile'));
const Settings = lazy(() => import('../pages/settings'));
const NotFound = lazy(() => import('../pages/not-found'));
const ContactForm = lazy(() => import('../pages/user-profile/contact-form'));
const SearchProducts = lazy(() => import('../pages/search-products'));

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const PrivateRoute = ({ condition, redirect, ...props }) => {
  condition = condition() || Storage.get('ACCESS_TOKEN');

  if (condition) return <Route {...props} />;
  return <Redirect to={redirect} />;
};
PrivateRoute.propTypes = {
  condition: PropTypes.func,
  redirect: PropTypes.string,
};

@withRouter
class Routes extends Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  _renderLazyComponent = (LazyComponent, params) => props => <LazyComponent {...props} {...params} />;
  componentDidMount() {
    sessionStorage.setItem('isFirstLogin', true);
  }

  _convertPath = path => {
    if (path) {
      const pathSplit = path?.split('/');
      return pathSplit?.[0] || path;
    }
    return path;
  };

  _getDetailRoute = () => {
    const { location } = this.props;
    const currentPath = location?.pathname;
    let pathName = currentPath.slice(4, currentPath.length);
    const detail = ROUTE_OPTION?.find(item => {
      return item?.path === this._convertPath(pathName);
    });
    return detail;
  };

  render() {
    const currentLang = localStorage.getItem('currentLang');
    if (!currentLang) {
      localStorage.setItem('currentLang', 'en');
    }
    const routeDetail = this._getDetailRoute();

    return (
      <VerticalBox>
        {routeDetail?.name ? <Header routeDetail={routeDetail} /> : <Header />}
        <Suspense
          fallback={
            <Page>
              <Loading size="large" />
            </Page>
          }
        >
          <ScrollToTop>
            <Switch>
              <Route exact path="/:lang/" component={this._renderLazyComponent(Home)} />
              <Route
                exact
                path="/:lang/products/:sortBy/:categoryId/:currency/:status"
                component={this._renderLazyComponent(Products)}
              />
              <Route exact path="/:lang/products" component={this._renderLazyComponent(Products)} />
              <Route exact path="/:lang/products/category/:id" component={this._renderLazyComponent(Products)} />

              <Route exact path="/:lang/products/key-word/:query" component={this._renderLazyComponent(Products)} />
              <Route exact path="/:lang/product-details/:id" component={this._renderLazyComponent(ProductDetails)} />
              <Route
                exact
                path="/:lang/product-details/:id/:sellAction"
                component={this._renderLazyComponent(ProductDetails)}
              />

              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/en"
                path="/:lang/settings"
                component={this._renderLazyComponent(Settings)}
              />
              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/en"
                path="/:lang/my-page"
                component={this._renderLazyComponent(UserProfile)}
              />
              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/en"
                path="/:lang/my-page/:tab"
                component={this._renderLazyComponent(UserProfile)}
              />
              <Route exact path="/:lang/search-products/" component={this._renderLazyComponent(SearchProducts)} />
              <Route
                exact
                path="/:lang/webview/product-details/:id"
                component={this._renderLazyComponent(ProductDetails)}
              />
              <Route path="/:lang/not-found" component={this._renderLazyComponent(NotFound)} />
              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/en"
                path="/:lang/contact-form"
                component={this._renderLazyComponent(ContactForm)}
              />
              <Redirect to="/en/" />
            </Switch>
          </ScrollToTop>
        </Suspense>
      </VerticalBox>
    );
  }
}

export default Routes;
