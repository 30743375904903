/* eslint-disable no-console */
import Web3 from 'web3';

const ethProvider = provider => {
  const getAccounts = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();

      return accounts;
    } catch (error) {
      console.error('Error', error);
    }
  };

  const getCurrentChainId = async () => {
    try {
      const web3 = new Web3(provider);
      const chainId = await web3.eth.getChainId();

      return chainId;
    } catch (error) {
      console.error('Error', error);
    }
  };

  const getBalance = async () => {
    try {
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const balance = await web3.eth.getBalance(accounts[0]);

      return Number(balance) * Math.pow(10, -18);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const signMessage = async () => {
    try {
      const pubKey = await provider.request({ method: 'eth_accounts' });
      const web3 = new Web3(provider);
      const message = '0x47173285a8d7341e5e972fc677286384f802f8ef42a5ec5f03bbfa254cb01fad';
      web3.currentProvider?.send(
        {
          method: 'eth_sign',
          params: [pubKey[0], message],
          from: pubKey[0],
        },
        (err, result) => {
          if (err) {
            return err;
          }
          console.log('Eth sign message => true', result);
        },
      );
    } catch (error) {
      console.log('error', error);
      return error;
    }
  };

  const getPrivateKey = async () => {
    return await provider?.request({ method: 'eth_private_key' });
  };

  const getWalletPrivateKey = async () => {
    const web3 = new Web3(provider);

    return (await web3.eth.accounts.create())?.privateKey || '';
  };

  return { getAccounts, getBalance, signMessage, getPrivateKey, getWalletPrivateKey, getCurrentChainId };
};

export default ethProvider;
