import { MainApi } from './endpoint';
import Storage from '../utils/storage';

export function getUserNonce(userId) {
  return MainApi.get(`/user/identity/nonce/${userId}`);
}

export function getRandomMessage() {
  return MainApi.get(`/random-message`);
}

// export function verifySignature(payload) {
//   return MainApi.post('/user/authentication/verify-signature', payload);
// }

export function login(payload) {
  // return MainApi.post('/user/authentication/sign-in', payload);
  return MainApi.post('/user/auth/sign-in', payload);
}

export function web3authlogin(payload) {
  return MainApi.post('/auth/login-web3-auth', payload);
}

export function getInitialData({ payload, headers }) {
  return MainApi.get('/user/initial', payload, {
    Authorization: `Bearer ${headers || Storage.get('ACCESS_TOKEN')}`,
  });
}

export function retrieveAccount(payload) {
  return MainApi.post('/user/auth/retrieve-account', payload, null, {
    Authorization: `Bearer ${payload || Storage.get('ACCESS_TOKEN')}`,
  });
}

export function retrieveTokenBet(payload) {
  return MainApi.post('/mccwallet/authorization-tokens', payload);
}

export function refreshAuthToken(payload) {
  return MainApi.post('/user/auth/refresh-auth-token', payload);
}

export function verifyEmail(data) {
  return MainApi.post('/user/auth/request-verify', data.value, null, {
    Authorization: `Bearer ${data.headers}`,
  });
}

export function verifyEmailLogin(payload) {
  return MainApi.post('/user/auth/send-code', payload);
}

export function authLogin(payload) {
  return MainApi.post('/user/auth/login', payload);
}

export function refreshToken(payload) {
  return MainApi.post('/user/auth/refresh_token', payload);
}
