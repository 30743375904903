import React from 'react';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethers } from 'ethers';

import authStore from '../store/auth';
import Confirmable from '../components/confirmable';
import Toast from '../components/toast';
import erc721aConfigs from '../configs/web3-contract/ERC721A-configs.json';
import wethConfigs from '../configs/web3-contract/weth-configs.json';
import Misc from './misc';
import CURRENCIES, { WRAPPED_CURRENCIES } from '../constants/currencies';
import { tokens } from '../constants/tokens';
import { CHAIN_LIST } from '../constants/chains';
import { t } from 'i18next';
import MaskLoading from '../components/mask-loading';
import { DAPP_WALLET_LIST } from '../constants/common';

let web3Instance;
let erc721acontract;
let wrappedNativeContract;
let usdtContract;
let regetContract = true;

const removeWeb3Instance = () => {
  if (web3Instance) {
    web3Instance.currentProvider?.disconnect();
    web3Instance = null;
  }
};

const generateDappUrl = ({ link, name }) => {
  if (link) {
    if (name === 'Metamask') {
      const dappUrl = `${window.location.href}`.trim();
      if (dappUrl.search('https://') !== -1) {
        const pageUrl = `${link}/dapp/${dappUrl.replace('https://', '')}`;
        window.location.href = pageUrl;
      }
    }
  }
};

const openMetamaskBrowser = () => generateDappUrl(DAPP_WALLET_LIST[0]);

const getWeb3Instance = async () => {
  if (Misc.isMobile && !window.ethereum) {
    if (!web3Instance) {
      if (Misc.isMobile && Misc.checkNotMetamaskBrowser()) {
        MaskLoading.close();
        const ok = await Confirmable.open({
          content: t('common:feature_available_on_metamask'),
        });
        if (ok) {
          openMetamaskBrowser();
        } else {
          const error = { code: 4001 };
          throw error;
        }
      } else {
        try {
          const walletConnectProvider = new WalletConnectProvider({
            infuraId: process.env.REACT_APP_INFURA_ID,
            qrcode: true,
            pollingInterval: 15000,
            qrcodeModalOptions: {
              mobileLinks: [
                'metamask',
                // "trust"
              ],
              desktopLinks: [
                'metamask',
                // "trust"
              ],
            },
          });

          web3Instance = new Web3(walletConnectProvider);
        } catch (error) {
          MaskLoading.close();
          return Confirmable.open({
            content: t('common:metamask_should_be_allowed'),
            hideCancelButton: true,
          });
        }

        regetContract = true;
      }
    }
  } else {
    if (!window.ethereum) {
      MaskLoading.close();
      return Confirmable.open({
        content: (
          <>
            {t('common:for_access_from_pc')}
            <br />
            <br />
            {t('common:for_access_from_mobile')}
            <br />
            <br />
            {t('common:otherwise_error_login')}
          </>
        ),
        hideCancelButton: true,
      });
    }
    try {
      let accounts = '';
      const checkFirstLogin = sessionStorage.getItem('isFirstLogin');
      if (checkFirstLogin) {
        accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
      }
      const { publicAddress } = authStore.initialData;

      if (!publicAddress || !accounts) {
        // eslint-disable-next-line no-throw-literal
        // throw { error: 'public address cannot null' };
        return;
      } else if (accounts[0]?.toLowerCase() != publicAddress?.toLowerCase()) {
        const requestPermission = await window.ethereum.request({
          method: 'wallet_requestPermissions',
          params: [{ eth_accounts: {} }],
        });
        const requestedAccounts = requestPermission?.[0]?.caveats?.[0]?.value;
        if (requestedAccounts.length > 1 || requestedAccounts?.[0].toLowerCase() !== publicAddress?.toLowerCase()) {
          // eslint-disable-next-line no-throw-literal
          throw { error: 'WALLET_NOT_CORRESPONDING' };
        }
        // MaskLoading.close();
        // return Confirmable.open({
        //   content: 'Please connect Metamask wallect corresponding to your account.',
        //   hideCancelButton: true,
        // });
        // eslint-disable-next-line no-throw-literal
        // throw { error: 'WALLET_NOT_CORRESPONDING' };
        // throw { error: 'Please connect Metamask wallect corresponding to logined account.' };
      }

      web3Instance = new Web3(window.ethereum);
    } catch (error) {
      throw error;
      // return Confirmable.open({
      //   content: 'MetaMaskを許可する必要があります。',
      //   hideCancelButton: true,
      // });
    } finally {
      // MaskLoading.close();
    }

    regetContract = true;
  }

  if (regetContract && web3Instance) {
    const chainId = await web3Instance.eth.getChainId();
    erc721acontract = new web3Instance.eth.Contract(erc721aConfigs, CHAIN_LIST[chainId]?.nft721aContractAddress);
    wrappedNativeContract = new web3Instance.eth.Contract(wethConfigs, tokens?.wrappedNative[chainId]?.address);
    usdtContract = new web3Instance.eth.Contract(wethConfigs, tokens?.usdt[chainId]?.address);
    regetContract = false;
  }

  return {
    web3: web3Instance,
    erc721acontract,
    wrappedNativeContract,
    usdtContract,
  };
};

const getWeb3Metamask = () => {
  if (window.ethereum && !Misc.isMobile) {
    try {
      return new Web3(window.ethereum);
    } catch (error) {
      throw error;
    }
  }
};

const getBalance = async walletAddress => {
  // const { web3, wethContract: childWethContract, prxContract: childPrxContract } = await getWeb3Instance();
  const { web3, wethContract: childWethContract } = await getWeb3Instance();

  if (web3) {
    try {
      const ethBalance = await web3.eth.getBalance(walletAddress);
      const wethBalance = await childWethContract.methods.balanceOf(walletAddress).call();
      // const prxBalance = await childPrxContract.methods.balanceOf(walletAddress).call();

      return {
        eth: +web3.utils.fromWei(ethBalance, 'ether'),
        weth: +wethBalance / 10 ** 18,
        // prx: +prxBalance / 10 ** 18,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('catch: ', e);
      if (process.env.REACT_APP_ENV === 'production') {
        Toast.error('MetaMaskでEthereumメインネットに変更してください。貴方は違うネットワークを使っています。');
      }
    }
  }

  return {};
};

const getBalanceNew = async walletAddress => {
  const {
    web3,
    wrappedNativeContract: wNativeContract,
    // wrappedNativeContract2: wNativeContract2,
  } = await getWeb3Instance();

  if (web3) {
    try {
      const isContract = (await web3.eth.getCode(wNativeContract?._address)) != '0x';
      const isContract2 = (await web3.eth.getCode(usdtContract?._address)) != '0x';
      const nativeBalance = await web3.eth.getBalance(walletAddress);
      const wNativeBalance = isContract ? await wNativeContract?.methods?.balanceOf(walletAddress)?.call() : undefined;
      const usdtBalance = isContract2 ? await usdtContract?.methods?.balanceOf(walletAddress)?.call() : undefined;
      // const prxBalance = await childPrxContract.methods.balanceOf(walletAddress).call();

      const chainId = await web3.eth.getChainId();

      return {
        native: +web3.utils.fromWei(nativeBalance, 'ether'),
        wNative: +wNativeBalance / 10 ** tokens.wrappedNative[chainId]?.decimals,
        usdt: +usdtBalance / 10 ** tokens.usdt[chainId]?.decimals,
        // prx: +prxBalance / 10 ** 18,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('catch: ', e);
      if (process.env.REACT_APP_ENV === 'production') {
        Toast.error('MetaMaskでEthereumメインネットに変更してください。貴方は違うネットワークを使っています。');
      }
    }
  }

  return {};
};

const checkBalance = async (authStore, item, value) => {
  const { native, wNative, usdt } = await getBalanceNew(authStore.initialData.publicAddress);
  let isEnoughBalance = true;

  if (WRAPPED_CURRENCIES.includes(item.currency)) {
    if (Number(value) > wNative) isEnoughBalance = false;
  } else {
    if (Number(value) > usdt) isEnoughBalance = false;
  }

  if (!isEnoughBalance) {
    /*eslint no-throw-literal: "error"*/
    const error = { error: 'BALANCE_NOT_ENOUGH' };
    throw error;
  }

  return { native, wNative, usdt };
};

const sign = async (nonce, publicAddress) => {
  const { web3 } = await getWeb3Instance();

  const message = `I am signing my one-time nonce: ${nonce}`;
  const signature = await web3.eth.personal.sign(message, publicAddress, '');

  return signature;
};

const requireApproveOnMobile = async t => {
  // if (Misc.isMobile) {
  //   MaskLoading.close();
  //   await Confirmable.open({
  //     content: t('product_details:you_signed'),
  //     hideCancelButton: true,
  //     acceptButtonText: t('common:ok'),
  //   });
  // }
};

const approveTokenErc721A = async (publicAddress, paymentAddress, tokenID, t) => {
  const instance = await getWeb3Instance();

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    instance.erc721acontract.methods.approve(paymentAddress, tokenID.toString()).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveTokenErc721ANew = async (publicAddress, paymentAddress, tokenID, t) => {
  const instance = await getWeb3Instance();

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    instance.erc721acontract.methods.approve(paymentAddress, tokenID.toString()).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveErc721 = async (publicAddress, paymentAddress, price, t) => {
  const instance = await getWeb3Instance();

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    instance.erc721acontract.methods.approve(paymentAddress, instance.web3.utils.toWei(price.toString(), 'mwei')).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveTokenErc1155 = async (publicAddress, paymentAddress, tokenID, t) => {
  const instance = await getWeb3Instance();

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    instance.erc1155contract.methods.approve(paymentAddress, tokenID.toString()).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveAllErc1155 = async (publicAddress, t) => {
  const instance = await getWeb3Instance();

  await requireApproveOnMobile(t);

  const EXCHANGE_CONTRACT_ADDRESS = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS;

  const approveHash = await new Promise((resolve, reject) => {
    instance.erc1155contract.methods.setApprovalForAll(EXCHANGE_CONTRACT_ADDRESS, true).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const checkIsApproveForAll = async (publicAddress, t) => {
  const instance = await getWeb3Instance();

  const EXCHANGE_CONTRACT_ADDRESS = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS;
  const isApprovedForAll = await instance.erc1155contract.methods
    .isApprovedForAll(publicAddress, EXCHANGE_CONTRACT_ADDRESS)
    .call();
  return isApprovedForAll;
};

const approveTokenByErc721 = async (publicAddress, paymentAddress, tokenId, t) => {
  const { erc721acontract } = await getWeb3Instance();

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    erc721acontract.methods.approve(paymentAddress, tokenId.toString()).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const allowanceEth = async (publicAddress, paymentAddress) => {
  const instance = await getWeb3Instance();

  const allowance = await instance.erc721acontract.methods.allowance(publicAddress, paymentAddress).call();

  return +allowance / 10 ** 6;
};

const allowanceWeth = async (publicAddress, paymentAddress) => {
  const instance = await getWeb3Instance();

  const allowance = await instance.wethContract.methods.allowance(publicAddress, paymentAddress).call();

  return +allowance / 10 ** 18;
};

const allowanceWNative = async (publicAddress, paymentAddress) => {
  const { wrappedNativeContract: wNativeContract } = await getWeb3Instance();
  const allowance = await wNativeContract.methods.allowance(publicAddress, paymentAddress).call();

  return +allowance / 10 ** 18;
};

const allowanceUsdt = async (publicAddress, paymentAddress) => {
  const { usdtContract } = await getWeb3Instance();
  const allowance = await usdtContract.methods.allowance(publicAddress, paymentAddress).call();

  // return +allowance / 10 ** 18;
  return +allowance / 10 ** 6;
};

const checkApprovedContract = async (tokenId, contractAddress) => {
  const { erc721acontract } = await getWeb3Instance();
  const result = await erc721acontract.methods.getApproved(tokenId).call();

  if (result.toLowerCase() === contractAddress.toLowerCase()) return true;
  return false;
};

const allowanceAll = async (publicAddress, paymentAddress) => {
  const wnativeAllowance = await allowanceWNative(publicAddress, paymentAddress);
  const usdtAllowance = await allowanceUsdt(publicAddress, paymentAddress);

  return { wnativeAllowance, usdtAllowance };
};

const getAllowanceByToken = async (currency, publicAddress, paymentAddress) => {
  const { wnativeAllowance, usdtAllowance } = await allowanceAll(publicAddress, paymentAddress);

  let allowance = null;
  if (WRAPPED_CURRENCIES.includes(currency)) {
    allowance = wnativeAllowance;
  } else {
    allowance = usdtAllowance;
  }

  return allowance;
};

const allowancePrx = async (publicAddress, paymentAddress) => {
  const instance = await getWeb3Instance();

  const allowance = await instance.prxContract.methods.allowance(publicAddress, paymentAddress).call();

  return +allowance / 10 ** 18;
};

const approveWeth = async (publicAddress, paymentAddress, price, t) => {
  const instance = await getWeb3Instance();

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    instance.wethContract.methods.approve(paymentAddress, instance.web3.utils.toWei(price.toString(), 'ether')).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveMaxWeth = async (publicAddress, contractExchangeAddress, t) => {
  const instance = await getWeb3Instance();
  const amountMax = process.env.REACT_APP_AMOUNT_MAX;

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    instance.wethContract.methods.approve(contractExchangeAddress, amountMax).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveMaxWNativeNew = async (publicAddress, contractExchangeAddress, t) => {
  const { wrappedNativeContract: wNativeContract } = await getWeb3Instance();
  const amountMax = process.env.REACT_APP_AMOUNT_MAX;

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    wNativeContract.methods.approve(contractExchangeAddress, amountMax).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveMaxPrx = async (publicAddress, contractExchangeAddress, t) => {
  const instance = await getWeb3Instance();
  const amountMax = process.env.REACT_APP_AMOUNT_MAX;

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    instance.prxContract.methods.approve(contractExchangeAddress, amountMax).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveMaxUsdt = async (publicAddress, contractExchangeAddress, t) => {
  const { usdtContract } = await getWeb3Instance();
  const amountMax = process.env.REACT_APP_AMOUNT_MAX;

  await requireApproveOnMobile(t);

  const approveHash = await new Promise((resolve, reject) => {
    usdtContract.methods.approve(contractExchangeAddress, amountMax).send(
      {
        from: publicAddress,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return approveHash;
};

const approveMax = async (publicAddress, t, currency) => {
  const contractExchangeAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS;
  await requireApproveOnMobile(t);

  // const transactionId =
  //   currency === 'PRX'
  //     ? approveMaxPrx(publicAddress, contractExchangeAddress, t)
  //     : approveMaxWeth(publicAddress, contractExchangeAddress, t);
  const transactionId = await approveMaxWeth(publicAddress, contractExchangeAddress, t);

  return transactionId;
};

const approveMaxNew = async (publicAddress, t, currency, type) => {
  const { web3 } = await getWeb3Instance();
  const chainId = await web3.eth.getChainId();
  // const contractExchangeAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS;
  const contractExchangeAddress = CHAIN_LIST[chainId]?.exchangeContractAddress;

  await requireApproveOnMobile(t);

  // const transactionId =
  //   currency === 'PRX'
  //     ? approveMaxPrx(publicAddress, contractExchangeAddress, t)
  //     : approveMaxWeth(publicAddress, contractExchangeAddress, t);
  const transactionHash = WRAPPED_CURRENCIES.includes(currency)
    ? await approveMaxWNativeNew(publicAddress, contractExchangeAddress, t)
    : await approveMaxUsdt(publicAddress, contractExchangeAddress, t);
  // const transactionId = await approveMaxWeth(publicAddress, contractExchangeAddress, t);
  let tx = await web3.eth.getTransactionReceipt(transactionHash);
  while (!tx || !tx.blockHash) {
    await new Promise(resolve => setTimeout(resolve, 3000));
    tx = await web3.eth.getTransactionReceipt(transactionHash).then(result => {
      return !!result?.status && result;
    });
  }

  if (!tx.status || tx.status === '0x0') {
    Confirmable.open({
      content: t('validation_messages:TRANSACTION_FAILED'),
      hideCancelButton: true,
    });

    return;
  }

  return transactionHash;
};

const sendTransactionERC20 = async (publicAddress, data, gasLimit) => {
  const instance = await getWeb3Instance();
  const contractExchangeAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS;
  const transactionHash = await new Promise((resolve, reject) => {
    instance.web3.eth.sendTransaction(
      {
        from: publicAddress,
        to: contractExchangeAddress,
        data,
        gasLimit,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });
  return transactionHash;
};

const sendTransactionERC20New = async (publicAddress, data, gasLimit, type) => {
  const { web3 } = await getWeb3Instance();
  const chainId = await web3.eth.getChainId();
  const gasPrice = await web3.eth.getGasPrice();
  let gasPriceMul = null;
  if (chainId === 137) {
    gasPriceMul = Math.floor(Number(gasPrice) * 1.5);
  } else {
    gasPriceMul = Math.floor(Number(gasPrice) * 1.2);
  }
  // const contractExchangeAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS;
  const contractExchangeAddress = CHAIN_LIST[chainId]?.exchangeContractAddress;

  const transactionHash = await new Promise((resolve, reject) => {
    web3.eth.sendTransaction(
      {
        from: publicAddress,
        to: contractExchangeAddress,
        data,
        gasLimit,
        gasPrice: gasPriceMul,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });
  return transactionHash;
};

const sendTransactionETH = async (publicAddress, data, gasLimit, amount) => {
  const instance = await getWeb3Instance();
  const contractExchangeAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS;
  const transactionHash = await new Promise((resolve, reject) => {
    instance.web3.eth.sendTransaction(
      {
        from: publicAddress,
        to: contractExchangeAddress,
        data,
        gasLimit,
        value: amount * 10 ** 18,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return transactionHash;
};

// eslint-disable-next-line no-unused-vars
const sendTransactionETHNew = async (publicAddress, data, gasLimit, amount, type) => {
  const { web3 } = await getWeb3Instance();
  const chainId = await web3.eth.getChainId();
  // const contractExchangeAddress = process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS;
  const contractExchangeAddress = CHAIN_LIST[chainId]?.exchangeContractAddress;

  const transactionHash = await new Promise((resolve, reject) => {
    web3.eth.sendTransaction(
      {
        from: publicAddress,
        to: contractExchangeAddress,
        data,
        gasLimit,
        value: amount * 10 ** 18,
      },
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      },
    );
  });

  return transactionHash;
};

const excuteTransaction = async (publicAddress, t, data, gasLimit = 300000, currency, amount) => {
  await requireApproveOnMobile(t);
  let transactionHash;

  currency === 'ETH'
    ? (transactionHash = await sendTransactionETH(publicAddress, data, gasLimit, amount))
    : (transactionHash = await sendTransactionERC20(publicAddress, data, gasLimit));
  return transactionHash;
};

const excuteTransactionNew = async (publicAddress, t, data, gasLimit = 300000, currency, amount, type) => {
  await requireApproveOnMobile(t);
  let transactionHash;

  // currency === 'ETH'
  //   ? (transactionHash = await sendTransactionETH(publicAddress, data, gasLimit, amount))
  //   : (transactionHash = await sendTransactionERC20(publicAddress, data, gasLimit));
  // currency === WRAPPED_CURRENCIES.includes(currency)
  //   ? (transactionHash = await sendTransactionETHNew(publicAddress, data, gasLimit, amount, type))
  //   : (transactionHash = await sendTransactionERC20New(publicAddress, data, gasLimit, type));
  transactionHash = await sendTransactionERC20New(publicAddress, data, gasLimit, type);
  return transactionHash;
};

const checkValidNetwork = async () => {
  const { web3 } = await getWeb3Instance();
  const chainId = await web3.eth.getChainId();

  if (
    (['development', 'staging', 'local'].includes(process.env.REACT_APP_ENV) && +chainId !== 5) ||
    (process.env.REACT_APP_ENV === 'production' && +chainId !== 1)
  ) {
    return false;
  }
  return true;
};

// check network corresponding to product
const checkCorrespondingNetwork = async chainId => {
  const { web3 } = await getWeb3Instance();
  try {
    const localChainId = await web3.eth.getChainId();

    if (localChainId === chainId) {
      await authStore.setCurrentChain(chainId);
      return true;
    }

    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      // params: [{ chainId: `0x${CHAIN_LIST[productChainId]?.chainId.toString(16)}` }],
      params: [{ chainId: `0x${chainId.toString(16)}` }],
    });

    await authStore.setCurrentChain(chainId);

    return true;
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (error.code === 4902) {
      const networkData = {
        chainId: `0x${CHAIN_LIST[chainId]?.chainId.toString(16)}`,
        chainName: CHAIN_LIST[chainId]?.displayName,
        rpcUrls: CHAIN_LIST[chainId]?.rpcUrls,
        blockExplorerUrls: CHAIN_LIST[chainId]?.blockExplorerUrls,
        nativeCurrency: {
          name: CHAIN_LIST[chainId]?.currency,
          symbol: CHAIN_LIST[chainId]?.symbol,
          decimals: CHAIN_LIST[chainId]?.decimals,
        },
      };

      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [networkData],
      });

      const nextCurrentChainId = await web3.eth.getChainId();

      if (+nextCurrentChainId === +chainId) {
        await authStore.setCurrentChain(chainId);
        return true;
      }

      // eslint-disable-next-line no-throw-literal
      throw { error: 'network_not_corresponding' };
    }

    throw error;
  }
};

const checkEnoughBalance = async (currency, price, publicAddress) => {
  const { eth, weth, prx } = await getBalance(publicAddress);

  if (currency === CURRENCIES.ETH) {
    if (price > eth) return false;
  } else if (currency === CURRENCIES.WETH) {
    if (price > weth) return false;
  } else if (currency === CURRENCIES.PRX) {
    if (price > prx) return false;
  }

  return true;
};

const onTransfer = async (exchangeContractAddress, data, gasLimit, currency, price, type) => {
  let transactionHash;

  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const signer = provider.getSigner();
  const tx = await signer.sendTransaction({
    data,
    to: exchangeContractAddress,
  });

  transactionHash = tx.hash;

  await tx.wait();
  return transactionHash;
};

const setApprovalForAll = async (poolAddress, publicAddress) => {
  const { erc721acontract } = await getWeb3Instance();
  const result = await erc721acontract.methods.setApprovalForAll(poolAddress, true).send({
    from: publicAddress,
  });

  return result;
};

export {
  getWeb3Instance,
  removeWeb3Instance,
  getBalance,
  getBalanceNew,
  checkBalance,
  sign,
  approveErc721,
  allowanceEth,
  allowanceWNative,
  allowanceUsdt,
  checkApprovedContract,
  allowanceAll,
  getAllowanceByToken,
  approveTokenErc721A,
  approveTokenErc721ANew,
  approveWeth,
  approveMax,
  approveMaxNew,
  sendTransactionERC20,
  sendTransactionETH,
  excuteTransaction,
  excuteTransactionNew,
  checkValidNetwork,
  checkCorrespondingNetwork,
  allowanceWeth,
  checkEnoughBalance,
  approveMaxPrx,
  allowancePrx,
  approveTokenByErc721,
  approveAllErc1155,
  checkIsApproveForAll,
  approveTokenErc1155,
  setApprovalForAll,
  onTransfer,
  openMetamaskBrowser,
  getWeb3Metamask,
};
