import { Form, Spin } from 'antd';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import Storage from '../utils/storage';

import { MobXProviderContext } from 'mobx-react';
import ButtonType from '../components/buttonType';
import Modal, { HeaderStyled } from '../components/modal';
import InputType from '../components/modal/inputType';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { Images } from '../theme';
import { BUTTON_CLOSE_ICON, EMAIL_BLACK, EMAIL_GRAY } from '../theme/images';
import LoginModal from './login-modal';
import Confirmable from '../components/confirmable';

const ContentStyled = styled.div`
  padding: 12px 24px 24px;
  position: relative;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .label-email {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .input-email {
    margin-bottom: 0px;
    border: 2px solid #0099fe;
    box-shadow: none;
    .ant-input {
      color: #000000;
      font-weight: 700;
      font-size: 14px;
      &::placeholder {
        color: #cecfd3;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
  .form-btn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: -12px;
  }
  .text-or {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: -8px 0px -8px;
    @supports (-webkit-text-stroke: 1.5px #000000) {
      color: white;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #000000;
      paint-order: stroke fill;
    }
  }
`;

const RegisterModal = ({ isOpen, onClose = () => {} }) => {
  const { t } = useTranslation('auth');
  const [loading, setLoading] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);

  const { auth, users } = useContext(MobXProviderContext);

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(30, t('validation_messages:MAX_LENGTH_30'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
    }),
    enableReinitialize: true,
    onSubmit: ({ email }) => {
      handleSubmitLogin(email);
    },
  });

  const { loginAuth0EmailPasswordLess, loginGoogle } = useWeb3Auth();

  const { touched, handleSubmit, values, isValid, errors, setFieldValue, setFieldTouched } = formik;

  const handleCloseModal = () => {
    formik.resetForm({});
    if (onClose) onClose();
  };

  const handleSubmitLogin = async value => {
    try {
      setLoading(true);
      const data = await loginAuth0EmailPasswordLess(value);
      handleLogin({
        access_token: data?.userInfo?.idToken,
        public_address: data?.publicAddress?.[0],
        app_pub_key: data?.appPubKey,
        ip_address: '',
        language_code: 'en',
      });
    } catch (error) {
      if (error?.code !== 5111) {
        Confirmable.open({
          content: <>{t('validation_messages:SOMETHING_WENT_WRONG')}</>,
          hideCancelButton: true,
        });
      }
    }
  };

  const handleLoginGoogle = async () => {
    setLoading(true);
    try {
      const data = await loginGoogle();
      handleLogin({
        access_token: data?.userInfo?.idToken,
        public_address: data?.publicAddress?.[0],
        app_pub_key: data?.appPubKey,
        ip_address: '',
        language_code: 'en',
      });
    } catch (error) {
      if (error?.code !== 5111) {
        Confirmable.open({
          content: <>{t('validation_messages:SOMETHING_WENT_WRONG')}</>,
          hideCancelButton: true,
        });
      }
    }
  };

  const handleLogin = async payload => {
    try {
      const { success, data } = await auth?.web3authlogin(payload);
      if (success) {
        Storage.set('ACCESS_TOKEN', data.access_token);
        Storage.set('PUBLIC_ADDRESS', payload.public_address);
        await auth.setLoggedIn(true);
        const { success: successUserInfo, data: userInfo } = await users.getUserInfo();
        if (successUserInfo) {
          auth.setInitialData({
            ...auth.initialData,
            publicAddress: payload.public_address,
            userImage: userInfo?.avatar_url,
            loggedIn: true,
          });
        }
      }
    } catch (error) {
      throw error;
    } finally {
      handleCloseModal();
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          if (!loading) handleCloseModal();
        }}
        destroyOnClose
        className="custom-modal"
        padding={0}
        title={null}
        width={500}
        closeIcon={<img className="icon-close" src={BUTTON_CLOSE_ICON} alt="icon" />}
      >
        <Spin spinning={loading}>
          <HeaderStyled>
            <div className="title-header" data-text={t('login.login')}>
              {t('login.login')}
            </div>
          </HeaderStyled>
          <ContentStyled>
            <FormContent>
              <Form.Item
                validateStatus={errors?.email && touched?.email ? 'error' : ''}
                help={
                  <div dangerouslySetInnerHTML={{ __html: touched?.email && errors?.email ? errors?.email : '' }} />
                }
              >
                <InputType
                  className="input-email"
                  prefix={
                    <img src={values?.email ? EMAIL_BLACK : EMAIL_GRAY} className="site-form-item-icon" alt="icon" />
                  }
                  placeholder={t('login.enter_your_email')}
                  name="email"
                  onChange={text => {
                    setFieldValue && setFieldValue('email', text.target.value || '');
                  }}
                  value={values?.email}
                  onBlur={() => setFieldTouched('email', true)}
                  disabled={loading}
                />
              </Form.Item>
              <div className="form-btn">
                <ButtonType typeBtn="blue" disabled={!isValid || loading} onClick={handleSubmit}>
                  Login with Email
                </ButtonType>
                <div className="text-or">Or</div>
                <ButtonType iconContent={Images.COLOR_GOOGLE_ICON} typeBtn="orange" onClick={handleLoginGoogle}>
                  Sign in with Google
                </ButtonType>
              </div>
            </FormContent>
          </ContentStyled>
        </Spin>
      </Modal>
      <LoginModal onClose={() => setIsOpenLogin(false)} isOpen={isOpenLogin} />
    </>
  );
};

export default RegisterModal;
