import { Button as AntButton } from 'antd';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Images } from '../theme';
import TextShadow from './text-shadow';
import Media from '../utils/media';

const StyledButton = styled(AntButton)`
  position: relative;
  width: ${props => props.w || '100%'};
  height: ${props => props.h || '46px'};
  border: none;
  padding: 0;
  background-color: transparent;

  background-image: ${props => `url(${`${props.bg}`})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover,
  &:focus,
  &:active {
    background-image: ${props => `url(${`${props.bg}`})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
  }
  &:disabled {
    cursor: not-allowed;
    background-image: ${props => `url(${`${props.bg}`})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
      background-image: ${props => `url(${`${props.bg}`})`};
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: transparent;
    }
  }
  .icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  ${Media.greaterThan(Media.SIZE.XXS)} {
    border: 2px solid #000000;
    border-radius: 10px;
    background-color: ${props => props.bgColor};

    background-image: none;

    &:hover,
    &:focus,
    &:active {
      border: 2px solid #000000;
      border-radius: 10px;
      background-color: ${props => props.bgColor};
      background-image: none;
    }
    &:disabled {
      cursor: not-allowed;
      background-image: none;
      border: 2px solid #000000;
      background-color: ${props => props.bgColor};

      &:hover,
      &:focus,
      &:active {
        background-image: none;
        border: 2px solid #000000;
        background-color: ${props => props.bgColor};
      }
    }
  }

  .text-content {
    margin-top: 3px;
  }
`;

const ButtonType = ({
  children,
  content,
  iconContent,
  w,
  h,
  fontSize,
  fontWeight,
  typeBtn,
  text,
  className,
  ...props
}) => {
  const bgBtn = useMemo(() => {
    let iconBtn = {
      bg: Images.BTN_WHITE,
      bgColor: '#ffffff',
      colorBox: null,
    };
    if (typeBtn === 'red') {
      iconBtn = {
        bg: Images.BTN_RED,
        bgColor: '#02bbff',
        colorBox: '#7B002E',
      };
    } else if (typeBtn === 'blue') {
      iconBtn = {
        bg: Images.BTN_BLUE,
        bgColor: '#02bbff',
        colorBox: '#000000',
      };
    } else if (typeBtn === 'gray') {
      iconBtn = {
        bg: Images.BTN_GRAY,
        bgColor: '#5c5279',
        colorBox: '#000000',
      };
    } else if (typeBtn === 'green') {
      iconBtn = {
        bg: Images.BTN_GREEN,
        bgColor: '#12f056',
        colorBox: '#006553',
      };
    } else if (typeBtn === 'black') {
      iconBtn = {
        bg: Images.BTN_BLACK,
        bgColor: '#061427',
        colorBox: '#000000',
        colorText: '#C7FFFF',
      };
    }
    return iconBtn;
  }, [typeBtn]);
  return (
    <StyledButton {...props} bgColor={bgBtn.bgColor} bg={bgBtn.bg} className={classnames(className)}>
      {iconContent && <img className="icon" src={iconContent} alt="icon" />}
      <TextShadow
        className="text-content"
        colorText={bgBtn.colorText}
        fontSize={fontSize}
        fontWeight={fontWeight}
        colorBox={bgBtn.colorBox}
      >
        {children}
      </TextShadow>
      {content && content()}
    </StyledButton>
  );
};

export default ButtonType;
