export const MAX_INPUT_VALUE = 1e20;
export const MAX_LENGTH_INPUT_VALUE_INTEGER = 12;
export const MAX_LENGTH_INPUT_VALUE_FRACTION = 4;
export const ALLOWED_MAX_SIZE_FILE = 5 * 1024 * 1024;

export const DAPP_WALLET_LIST = [
  {
    name: 'Metamask',
    link: 'https://metamask.app.link',
    // icon: metamaskWallet,
  },
  {
    name: 'Trust',
    link: 'https://link.trustwallet.com/open_url?coin_id=60&url=',
    // icon: trustWallet,
  },
];

export const TYPE_POINT = {
  MCC_POINT: 'MCC_POINT',
  FREE_POINT: 'FREE_POINT'
}
