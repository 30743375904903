import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

const StyledDiv = styled.div`
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s;
`

const Clickable = ({ children, className, onClick, effect, ...props }) => (
  <StyledDiv
    {...props}
    className={classnames({ effect }, className)}
    onClick={onClick}
  >
    {children}
  </StyledDiv>
)
Clickable.propTypes = {
  onClick: PropTypes.func,
  effect: PropTypes.bool
}
Clickable.defaultProps = {
  effect: true
}

export default Clickable
