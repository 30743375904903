import { CHAIN_LIST } from '../constants/chains';
import Request from './request';
import Storage from './storage';

export const logout = () => {
  Storage.remove('ACCESS_TOKEN');
  Storage.remove('PUBLIC_ADDRESS');
  Request.removeAccessToken();
  window.location.href = "/"
};

export const getNetworksEnv = env => Object.values(CHAIN_LIST).find(item => item.env?.includes(env))?.env;

export const getNetworks = env => Object.values(CHAIN_LIST).filter(item => item.env?.includes(env));

export const getNetworkSelected = (env, chainIdSelected) => {
  const networks = getNetworks(env);
  return chainIdSelected ? networks.find(item => item.chainId === +chainIdSelected) || networks[0] : networks[0];
};
