import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { BUTTON_CLOSE_ICON } from '../theme/images';
import Media from '../utils/media';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent !important;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin: auto;

    .ant-modal-close {
      right: 20px;
      background: transparent;
      .ant-modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        position: absolute;
        top: 4px;
        right: -16px;

        .icon-close {
          width: 32px;
          height: 32px;
        }
      }
    }

    .ant-modal-body {
      padding: ${({ padding }) => padding ?? 30}px;
      /* ${Media.lessThan(Media.SIZE.XS)} {
        padding: 0 10px !important;
      } */
    }
  }

  &.custom-modal {
    .ant-modal-header {
      background-color: #040405;
      border-bottom: none;
      .title {
        color: #fff !important;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
      }
    }
    &.visible {
      .ant-modal-content {
        overflow: visible;
        .ant-modal-close {
          display: none;
        }
      }
    }
    .ant-modal-content {
      background-color: #00adfe !important;
      border: 1px solid #1a1a1a;
      border-radius: 8px;
      border: 2px solid black;
      .title {
        color: #040c14;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 1px;
        width: calc(100%);
        height: 4px;
        background-color: #0084dd;
      }
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #3cc8fc;
  }
`;

export const HeaderStyled = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #0e7ff2;
  border-bottom: 2px solid #0e69f2;

  .title-header {
    z-index: 2;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;

    position: relative;
    &::after {
      content: attr(data-text);
      position: absolute;
      bottom: 2px;
      left: 0px;
      color: white;
    }
    @supports (-webkit-text-stroke: 2px #000000) {
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #000000;
      paint-order: stroke fill;
    }
  }
`;

export const ContentStyled = styled.div`
  padding: 12px 24px 24px;
  position: relative;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .label-email {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
`;

const ModalComponent = ({ children, title, className, width, ...props }) => (
  <StyledModal
    centered
    width={width ?? 600}
    footer={null}
    closeIcon={<img className="icon-close" src={BUTTON_CLOSE_ICON} alt="icon" />}
    className={className}
    title={title}
    {...props}
  >
    {children}
  </StyledModal>
);

ModalComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.object,
  className: PropTypes.string,
};

export default ModalComponent;
