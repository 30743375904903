import { tokens } from './tokens';

export const CHAIN_LIST = {
  1: {
    env: ['production'],
    chainId: 1,
    displayName: 'Ethereum Mainnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://etherscan.io/'],
    rpcUrls: [process.env.REACT_APP_RPC_URL, 'https://rpc.ankr.com/eth', 'https://ethereum.publicnode.com'],
    exchangeContractAddress: process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS,
    nft721aContractAddress: process.env.REACT_APP_ERC721A_CONTRACT_ADDRESS,
    borrowContractAddress: '',
    // erc20Token: [tokens.wrappedNative[1], tokens.usdt[1]],
    erc20Token: [tokens.usdt[1]],
  },
  11155111: {
    env: ['develop', 'local', 'staging'],
    chainId: 11155111,
    displayName: 'Sepolia Testnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    rpcUrls: [process.env.REACT_APP_RPC_URL, 'https://eth-sepolia-public.unifra.io', 'https://rpc2.sepolia.org'],
    exchangeContractAddress: process.env.REACT_APP_EXCHANGE_CONTRACT_ADDRESS,
    nft721aContractAddress: process.env.REACT_APP_ERC721A_CONTRACT_ADDRESS,
    borrowContractAddress: '',
    // erc20Token: [tokens.wrappedNative[11155111], tokens.usdt[11155111]],
    erc20Token: [tokens.usdt[11155111]],
  },
};
