import styled, { css } from 'styled-components';

const TextShadowStyled = styled.div`
  position: relative;
  font-weight: ${props => props.fontWeight || '700'};
  font-size: ${props => props.fontSize || '12px'};
  color: ${props => props.colorBox || '#000000'};
  width: fit-content;
  margin: auto;
  ${props => {
    if (props.colorBox) {
      return css`
        &::after {
          content: attr(data-text);
          position: absolute;
          bottom: 2px;
          left: 0px;
          color: ${props => props.colorText || '#ffffff'};
        }

        @supports (-webkit-text-stroke: 2px #006553) {
          -webkit-text-stroke-width: ${props => props.strokeText || '2px'};
          -webkit-text-stroke-color: ${props => props.colorBox || '#000000'};
          paint-order: stroke fill;
        }
      `;
    }
  }}
`;

export default function TextShadow({ strokeText, fontSize, fontWeight, colorText, colorBox, children, ...rest }) {
  return (
    <TextShadowStyled
      strokeText={strokeText}
      fontWeight={fontWeight}
      fontSize={fontSize}
      colorText={colorText}
      colorBox={colorBox}
      {...rest}
      data-text={children}
    >
      {children}
    </TextShadowStyled>
  );
}
