import { Divider, Popover } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Clickable from '../components/clickable';
import Thumbnail from '../components/thumbnail';
import Typography from '../components/typography';
import { Colors, Images } from '../theme';
import Misc from '../utils/misc';
import Storage from '../utils/storage';
import ChainBox from './chain-box';

function UserBox({ authStore, onLogout, onOpenMyPage, publicAddress }) {
  const { t } = useTranslation(['common']);
  const address = Storage.get('PUBLIC_ADDRESS');
  // const [open, setOpen] = useState(false);

  const handleLogout = e => {
    // setOpen(false);
    if (onLogout) onLogout(e);
  };

  const handleOpenMyPage = e => {
    if (onOpenMyPage) onOpenMyPage(e);
  };

  return (
    <Clickable className="user-box">
      <Popover
        placement="bottomRight"
        trigger="hover"
        showArrow={false}
        content={
          <>
            {(
              <StyledPopoverContent>
                <div className="user-box">
                  <div className="user-infor">
                    <Typography size="small" className="address">
                      {Misc.trimPublicAddress(publicAddress || address, 12, 6)}
                    </Typography>
                  </div>
                </div>
                <ChainBox />
                <Divider className="line" />
                <div className="action-box">
                  <Clickable className="action-item mt-14" onClick={handleOpenMyPage}>
                    <Thumbnail url={Images.BLACK_USER_ICON} size={24} className="icon-action" />
                    <span className="action-name">{t('header.my_page')}</span>
                  </Clickable>
                  <Divider className="line mt-20" />
                  <Clickable className="action-item mt-14" onClick={handleLogout}>
                    <Thumbnail url={Images.BLACK_LOGOUT_ICON} size={22} className="icon-action" />
                    <span className="action-name">{t('header.logout')}</span>
                  </Clickable>
                </div>
              </StyledPopoverContent>
            )}
          </>
        }
      >
        <div className="_avatar-box">
          <Thumbnail
            rounded
            size={32}
            className="avatar-image"
            url={authStore.initialData.userImage}
            placeholderUrl={Images.USER_PLACEHOLDER}
          />
          {/* {Misc.trimPublicAddress(publicAddress || '', 6, 3)} */}
        </div>
      </Popover>
    </Clickable>
  );
}

// export default UserBox;
export default inject(stores => ({
  authStore: stores.auth,
}))(
  observer(({ authStore, ...props }) => {
    const { username = '', email = '', publicAddress = '', userImage = '' } = authStore?.initialData;
    return (
      <UserBox
        {...props}
        authStore={authStore}
        username={username}
        email={email}
        publicAddress={publicAddress}
        userImage={userImage}
      />
    );
  }),
);

const StyledPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 202px;
  padding: 20px 16px;

  .mt-20 {
    margin-top: 20px !important;
  }
  .mt-16 {
    margin-top: 16px !important;
  }
  .mt-14 {
    margin-top: 14px !important;
  }
  .line {
    margin: 10px 0;
    background: #F3F3F3;
    height: 1.5px;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .user-box {
    display: flex;
    align-items: center;
    gap: 10px;
    .username {
      font-weight: 700;
      font-size: 16px;
      color: ${Colors.TEXT};
      white-space: nowrap;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .address {
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
  }
  .action-box {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    .action-item {
      display: flex;
      align-items: center;
      gap: 14px;
      .action-name {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
    }
  }
`;
