import lodash from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';

import { MobXProviderContext } from 'mobx-react';
import styled from 'styled-components';
import Loading from '../components/loading';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import authStore from '../store/auth';
import usersStore from '../store/users';
import { Colors } from '../theme';
import i18n from '../translations/i18n';
import Request from '../utils/request';
import Storage from '../utils/storage';

yup.setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    email: 'email',
  },
});

const Init = ({ children }) => {
  const { products: productsStore, auth } = useContext(MobXProviderContext);
  const { isLoading, refreshToken, expiredToken } = useWeb3Auth();
  const [initing] = useState(true);

  const fetchPriceRate = async () => {
    const priceRateResult = await productsStore.getPriceRate();

    const priceRates = lodash.reduce(
      priceRateResult?.data?.records,
      (result, item) => {
        result[item?.id] = +item?.exchange_rate;
        return result;
      },
      {},
    );

    if (priceRateResult.success) {
      authStore.setInitialData({
        ...authStore.initialData,
        ...priceRates,
      });
    }
  };

  const refreshAccessToken = useCallback(async () => {
    const { success, data } = await auth.refreshToken({ refresh_token: refreshToken });

    if (success) {
      Storage.set('ACCESS_TOKEN', data.accessToken);
      Storage.set('EXPIRED_TOKEN', data.expiredAt);
      Storage.set('REFRESH_TOKEN', data.refreshToken);
      Request.setAccessToken(data.accessToken);
    }
  }, [auth, refreshToken]);

  const isTokenExpiring = () => {
    if (!expiredToken) {
      return false;
    }
    const timeDifference = Number(expiredToken) - moment.utc().unix() * 1000;
    const oneDayMinutes = 86400000;
    return timeDifference <= oneDayMinutes;
  };

  useEffect(() => {
    if (isTokenExpiring()) {
      refreshAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAccessToken]);

  const getUser = async () => {
    const { success, data } = await usersStore.getUserInfo();
    if (success) {
      authStore.setInitialData({
        ...authStore.initialData,
        publicAddress: data?.public_address,
        userImage: data?.avatar_url,
        loggedIn: true,
      });
    }
  };

  useEffect(() => {
    const ACCESS_TOKEN = Storage.get('ACCESS_TOKEN');

    if (ACCESS_TOKEN) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // walletconnect for mobile
  useEffect(() => {
    if (window.location.pathname.slice(0, 3) === '/en') {
      i18n.changeLanguage('en');
    }

    if (window.location.pathname.slice(0, 3) === '/zh') {
      i18n.changeLanguage('zh');
    }

    fetchPriceRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !initing || !isLoading ? (
    children
  ) : (
    <PreloadingStyled>
      <Loading size="full-screen" />
    </PreloadingStyled>
  );
};

Init.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Init;

const PreloadingStyled = styled.div`
  background-color: ${Colors.BOX_BACKGROUND};
`;
